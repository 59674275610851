<template lang='pug'>
	.container
		.wallet-form-box.card
			.card-body
				router-link.btn.btn-close(to='/')
					i.fa.fa-times
				form(@submit.prevent='step++')
					h4 KYC Wizard
					transition(name='fade' mode='out-in')
						.ask(v-if='step === 1' key='1')
							div.mt-3
								h5 Personal Information
							.row.mt-2
								.col-md-4.mb-2
									span First Name *
									input.form-control(type='text' placeholder='First Name' v-model='kyc.firstName' autofocus required)
								.col-md-4.mb-2
									span Middle Name
									input.form-control(type='text'  placeholder='Middle Name' v-model='kyc.middleName')
								.col-md-4.mb-2
									span Last Name *
									input.form-control(type='text'  placeholder='Last Name' v-model='kyc.lastName' required)
								.col-md-4.mb-2
									span Email Address *
									input.form-control(type='email' placeholder='Email Address' v-model='kyc.email' required)
								.col-md-4.mb-2
									span Gender *
									b-select(v-model='kyc.gender' :options='genders' required)
								.col-md-4.mb-2
									span Date of Birth *
									input.form-control(v-model='kyc.birthDate' type='date' required)
								.col-md-4.mb-2
									span Occupation *
									input.form-control(v-model='kyc.occupation' type='occupation' placeholder='Current Occupation' required)
							div.mt-4
								h5 Family Information
							.row.mt-3
								.col-md-4.mb-2
									span Father's Name *
									input.form-control(type='text' v-model='kyc.fatherName' placeholder='Full Name of Father' required)
								.col-md-4.mb-2
									span Mother's Name *
									input.form-control(type='text' v-model='kyc.motherName' placeholder='Full Name of Mother' required)
								.col-md-4.mb-2
									span Grandfather's Name *
									input.form-control(type='text' v-model='kyc.grandFatherName' placeholder='Full Name of Grandfather' required)
								.col-md-4.mb-2
									span Spouse Name
									input.form-control(type='text' v-model='kyc.spouseName' placeholder='Full Name of Spouse')
							
						.ask(v-else-if='step === 2' key='2')
							div.mt-4 
								h5 Permanent Address
							.row.mt-3
								.col-md-4.mb-2
									span Province *
									b-select(v-model='kyc.permanent_province' :options='provinces' @change='reset("PROVINCE", "permanent")' required)
								.col-md-4.mb-2
									span District *
									b-select(v-model='kyc.permanent_district' :options='districts(kyc.permanent_province)' @change='reset("DISTRICT", "permanent")' required)
								.col-md-4.mb-2
									span CityType *
									b-select(v-model='kyc.permanent_cityType' :options='cityTypes(kyc.permanent_province, kyc.permanent_district)' @change='reset("CITYTYPE", "permanent")' required)
								.col-md-4.mb-2
									span City Name *
									b-select(v-model='kyc.permanent_cityName' :options='cityNames(kyc.permanent_province, kyc.permanent_district, kyc.permanent_cityType)' required)
								.col-md-4.mb-2
									span Ward No. *
									input.form-control(v-model='kyc.permanent_wardNo' type='number' required)
							div.mt-4.d-flex.flex-row 
								h5 Temporary Address
								a.ml-3(@click='copy' type="button") ( Same as Above? )
								
							.row.mt-3
								.col-md-4.mb-2
									span Province *
									b-select(v-model='kyc.temporary_province' :options='provinces' @change='reset("PROVINCE", "temporary")' required)
								.col-md-4.mb-2
									span District *
									b-select(v-model='kyc.temporary_district' :options='districts(kyc.temporary_province)' @change='reset("DISTRICT", "temporary")' required)
								.col-md-4.mb-2
									span CityType *
									b-select(v-model='kyc.temporary_cityType' :options='cityTypes(kyc.temporary_province, kyc.temporary_district)' @change='reset("CITYTYPE", "temporary")' required)
								.col-md-4.mb-2
									span City Name *
									b-select(v-model='kyc.temporary_cityName' :options='cityNames(kyc.temporary_province, kyc.temporary_district, kyc.temporary_cityType)' required)
								.col-md-4.mb-2
									span Ward No. *
									input.form-control(v-model='kyc.temporary_wardNo' type='number' required)
						.ask(v-else-if='step === 3' key='3').container.m-3
							form(@submit.prevent='uploadNow')
								div.mt-4
									h5 Identification Document
								.row.mt-3
									.col-md-4.mb-2
										span Type of Document *
										b-select(v-model='kyc.documentType' :options='availableDocuments' required)
									.col-md-4.mb-2
										span Document ID *
										input.form-control(v-model='kyc.documentID' required)
									.col-md-4.mb-2
										span Document Issued From *
										input.form-control(v-model='kyc.documentIssuedFrom' required)
									.col-md-4.mb-2
										span Document Issued Date *
										input.form-control(v-model='kyc.documentIssuedDate' type='date' required)
									.col-md-4.mb-2
										span Nationality *
										input.form-control(v-model='kyc.nationality' required)
								.row.mt-3
									.col-md-4.mb-2
										b-form-file(v-model='kyc.photo' placeholder='Upload Recent Photo *' @input="imageFromFile('#profileImage',kyc.photo)" accept='image/*' required)
										img#profileImage.rounded
									.col-md-4.mb-2
										b-form-file(v-model='kyc.documentFront' placeholder='Upload Front Part of Document *' @input="imageFromFile('#documentFront',kyc.documentFront)" accept='image/*' required)
										img#documentFront.rounded
									.col-md-4.mb-2
										b-form-file(v-model='kyc.documentBack' placeholder='Upload Back Part of Document *' @input="imageFromFile('#documentBack',kyc.documentBack)" accept='image/*' required)
										img#documentBack.rounded
								.row.mt-3
									.col
										button.btn.btn-primary(:disabled='loading') 
											.spinner-border.spinner-border-sm(role='status' v-if='loading' aria-hidden="true")
											span.ml-1 Confirm KYC
					.border-top.m-4
					.m-4
						button.btn.btn-primary.float-right(v-if='step != 3') Next
						button.btn.btn-secondary.float-left(type='button' @click='step--' v-if='step > 1') Back
</template>

<script>
import api from "@/helpers/api";
import axios from "@/axios"
export default {
	name: "kyc-form",
	data() {
		return {
			geo: [],
			kyc: {
				firstName: "",
				middleName: "",
				lastName: "",
				occupation: "",
				birthDate: "",
				email: "",
				gender: "",
				nationality: "",
				permanent_province: "",
				permanent_district: "",
				permanent_cityType: "",
				permanent_cityName: "",
				permanent_wardNo: "",
				temporary_province: "",
				temporary_district: "",
				temporary_cityType: "",
				temporary_cityName: "",
				temporary_wardNo: "",
				fatherName: "",
				motherName: "",
				grandFatherName: "",
				spouseName: "",
				documentType: "Citizenship",
				documentID: "",
				documentIssuedDate: "",
				documentIssuedFrom: "",
				photo: null,
				documentFront: null,
				documentBack: null,
				documentFile: null
			},
			loading: false,
			availableDocuments: ["Citizenship", "Licence", "Passport"],
			genders: ["Male", "Female", "Other"],
			step: 1
		};
	},
	computed: {
		provinces() {
			return this.geo.map(geo => {
				return geo.province;
			});
		}
	},
	methods: {
		copy() {
			this.kyc.temporary_province = this.kyc.permanent_province;
			this.kyc.temporary_district = this.kyc.permanent_district;
			this.kyc.temporary_cityType = this.kyc.permanent_cityType;
			this.kyc.temporary_cityName = this.kyc.permanent_cityName;
			this.kyc.temporary_wardNo = this.kyc.permanent_wardNo;
		},
		reset(level, type) {
			switch (level) {
				case "PROVINCE": {
					this.kyc[type + "_district"] = "";
					this.kyc[type + "_cityType"] = "";
					this.kyc[type + "_cityName"] = "";
					break;
				}
				case "DISTRICT": {
					this.kyc[type + "_cityType"] = "";
					this.kyc[type + "_cityName"] = "";
					break;
				}
				case "CITYTYPE": {
					this.kyc[type + "_cityName"] = "";
					break;
				}
			}
		},
		districts(province) {
			if (province != "")
				return this.geo
					.find(geo => geo.province === province)
					.districts.map(district => district.district);
			return [];
		},
		imageFromFile(id, file) {
			if (file) {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function(evt) {
					document.querySelector(id).src = evt.target.result;
				};
			}
		},
		cityTypes(province, district) {
			if (province && district) {
				let districts = this.geo
					.find(geo => geo.province === province)
					.districts.find(dis => district === dis.district);
				if (districts) {
					return districts.cityTypes
						.filter(cityType => cityType.cities.length !== 0)
						.map(city => {
							return city.cityType;
						});
				}
			}
			return [];
		},
		cityNames(province, district, cityType) {
			if (province && district && cityType) {
				let districts = this.geo
					.find(geo => geo.province === province)
					.districts.find(dis => district === dis.district);
				if (districts) {
					let cType = districts.cityTypes.find(
						cType => cType.cityType === cityType
					);
					if (cType) {
						return cType.cities;
					}
				}
			}
			return [];
		},
		uploadNow() {
			this.loading = true;
			const info = new FormData();

			Object.keys(this.kyc).forEach(detailKey => {
				info.append(detailKey, this.kyc[detailKey]);
			});
			info.append("documentFile", this.kyc.documentFront);
			info.append("documentFile", this.kyc.documentBack);
			if (this.kyc._id) {
				axios
					.put("kyc", info)
					.then(res => {
						console.log(this.kyc);
						if (res.data.code === 1) {
							this.$router.push("/");
						}
					})
					.catch(er => {});
			} else
				axios
					.post("kyc", info)
					.then(res => {
						if (res.data) {
							this.$router.push("/");
						}
					})
					.catch(er => {});
		}
	},
	async mounted() {
		const checkKYC = await api.isKycNotVerified();
		try {
			const res = await axios.get("kyc");
			if (res.data && res.data.data && Object.keys(res.data.data).length > 0) {
				this.kyc = { ...this.kyc, ...res.data.data };
				this.kyc = { ...this.kyc, ...this.kyc.family };
				["province", "district", "cityType", "cityName", "wardNo"].forEach(
					v => {
						this.kyc[`permanent_${v}`] = this.kyc.permanentAddress[v];
						this.kyc[`temporary_${v}`] = this.kyc.temporaryAddress[v];
					}
				);
				this.kyc.documentType = this.kyc.documents.type;
				this.kyc.documentID = this.kyc.documents.idNumber;
				this.kyc.documentIssuedDate = this.kyc.documents.issuedDate;
				this.kyc.documentIssuedFrom = this.kyc.documents.issuedFrom;
				delete this.kyc.documents;
				delete this.kyc.family;
				delete this.kyc.avatar;
				delete this.kyc.commentsHistory;
				delete this.kyc.permanentAddress;
				delete this.kyc.temporaryAddress;
				delete this.kyc.verified
			}
		}
		catch (er) {
			console.log("Something went wrong.")
		}
		axios.get("/staticData/nepalLocation").then(r => {
			if (r.data && r.data.data) {
				this.geo = r.data.data;
			}
		});
	}
};
</script>
<style scoped>
#profileImage,
#documentBack,
#documentFront {
	max-width: 200px;
	max-height: 200px;
	margin: 5px;
}
</style>